<template>
	<div>
		<wager-summary-filters
			:username-filter="usernameFilter"
			:product-filter.sync="productFilter"
			:product-options="productOptions"
			:fromdate-filter.sync="fromdateFilter"
			:todate-filter.sync="todateFilter"
		/>
        <b-alert
            variant="primary"
            show
            dismissible
        >
            <div class="alert-body">
                Datas below is demo
            </div>
        </b-alert>
		<b-card
			no-body
		>
			<b-table
				ref="refWagerSummaryTable"
				class="position-relative table-white-space mb-0"
				:items="wagerSummaryLists"
				responsive
				:fields="tableColumns"
				primary-key="index"
				show-empty
				empty-text="No matching records found"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

                <template #cell(eligible_stake_amount)="data">
                    {{ numberFormat(data.item.eligible_stake_amount) }}
                </template>

				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalWagerSummary"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Add Bonus Modal -->
		<!-- <add-bonus-modal/> -->
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal, BAlert,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed } from '@vue/composition-api'
import { numberFormat, formatDateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import WagerSummaryFilters from './WagerSummaryFilters.vue'

export default {
	components: {
		WagerSummaryFilters,
		// AddBonusModal: () => import('./AddBonusModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
        BAlert,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			roleId: null,
			content: null,
		}
	},
	methods: {
		showAddBonusModal() {
			this.$bvModal.show('modal-add-bonus')
		},
	},
	setup() {
        const dataDemos = [
            {
                username: 'qatest04',
                product: 'Sports 247',
                win_loss: 0,
                stake: 8,
                count: 8,
                eligible_stake_amount: 1694,
                eligible_count: 0,
                rebate_amount: 0,
                rebate_date: '04-21-2022 07:00:00',
                bonus_rollover_amount: 0,
                created_at: '04-22-2022 11:00:49',
                updated_at: '04-22-2022 11:01:33'
            },
        ]
		const wagerSummaryLists = ref(dataDemos)
		const perPage = ref(20)
		const totalWagerSummary = ref(0)
		const currentPage = ref(1)
		const usernameFilter = ref(null)
		const productFilter = ref(null)
        const productOptions = ref([])
		const fromdateFilter = ref(null)
		const todateFilter = ref(null)
		const refWagerSummaryTable = ref(null)
		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'username', label: 'Login name' },
			{ key: 'product', label: 'Product' },
            { key: 'win_loss', label: 'Win loss' },
            { key: 'stake', label: 'Stake' },
            { key: 'count', label: 'Count' },
			{ key: 'eligible_stake_amount', label: 'Eligible stake amount' },
            { key: 'eligible_count', label: 'Eligible count' },
            { key: 'rebate_amount', label: 'Rebate amount' },
            { key: 'rebate_date', label: 'Rebate date' },
            { key: 'bonus_rollover_amount', label: 'Bonus rollover amount' },
			{ key: 'created_at', label: 'Created at' },
			{ key: 'updated_at', label: 'Updated at' },
		]
		const dataMeta = computed(() => {
			const localItemsCount = refWagerSummaryTable.value ? refWagerSummaryTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalWagerSummary.value,
			}
		})
		const refetchData = () => {
			refWagerSummaryTable.value.refresh()
		}

		watch([currentPage, perPage, usernameFilter, productFilter, fromdateFilter, todateFilter], () => {
			refetchData()
		})

		return {
			tableColumns,
			perPage,
			currentPage,
			totalWagerSummary,
			dataMeta,
			refWagerSummaryTable,

			wagerSummaryLists,
			refetchData,

			usernameFilter,
			productFilter,
			productOptions,
			fromdateFilter,
			todateFilter,
			formatDateTime,
            numberFormat,
		}
	},
}
</script>
<style>
	.b-table-sticky-column {
		right: 0!important;
	}
	td.b-table-sticky-column {
		background-color: inherit;
	}
</style>